export const useMembersArea = () => {
  const route = useRoute()
  const event = useRequestEvent()
  const currentSite = inject("currentSite")
  const userStore = useUserStore()
  const { $api } = useNuxtApp()

  const path = computed(() => {
    if (import.meta.server && event) {
      return event.node.req.url || ""
    }
    return route.path
  })

  const inMembersArea = computed(() => {
    return path.value.startsWith("/members")
  })

  const inTourArea = computed(() => {
    return !inMembersArea.value
  })

  const isGuest = computed(() => {
    return false // ???
  })

  const streamingOnly = computed(() => {
    if (currentSite.value.cachedSlug === "pornplus") {
      return userStore.accessLevel === "streaming"
    } else {
      return false
    }
  })

  const hasPlatinum = computed(() => {
    switch (currentSite.value.installation) {
      case "bp":
        return true
      default:
        return userStore.full && userStore.lifetime
    }
  })

  const nextUpgradeAvailableType = computed(() => {
    if (userStore.expired) {
      return "reactivate"
    } else if (!hasPlatinum.value) {
      return "platinum"
    } else if (userStore.trial) {
      return "trial"
    } else if (streamingOnly.value) {
      return "downloads"
    } else if (isGuest.value) {
      return "guest"
    }
  })

  const reactivationCost = computed(() => {
    return 14.95
  })

  const trialUpgradeCost = computed(() => {
    return 24.95
  })

  const quarterlyUpgradeCost = computed(() => {
    return 39.95
  })

  const downloadsUpgradeCost = computed(() => {
    return 39.95
  })

  const guestJoinCost = computed(() => {
    return 14.95
  })

  const platinumUpgradeCost = computed(() => {
    return 189.95
  })

  const performingMembershipUpgrade = ref(false)
  const performMembershipUpgrade = async (
    type,
    { emit = null, offerId = null } = {},
  ) => {
    performingMembershipUpgrade.value = true

    try {
      const response = await $api(`/api/upgrade/${type}`, {
        method: "POST",
        body: JSON.stringify({
          option: offerId ? offerId : type,
        }),
      })

      if (emit) {
        if (response.result == "success") {
          emit("upgrade-success", response.redirect)
        } else {
          emit("upgrade-error", response.error)
        }
      }

      return response
    } finally {
      performingMembershipUpgrade.value = false
    }
  }

  return {
    downloadsUpgradeCost,
    guestJoinCost,
    inMembersArea,
    inTourArea,
    nextUpgradeAvailableType,
    performMembershipUpgrade,
    performingMembershipUpgrade,
    platinumUpgradeCost,
    quarterlyUpgradeCost,
    reactivationCost,
    streamingOnly,
    trialUpgradeCost,
  }
}
